import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import { Container } from "../components/global"
import styled from "styled-components"

import Footer from "../components/sections/footer"
import CookieConsent from "react-cookie-consent";

import { useIntl } from "gatsby-plugin-react-intl"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const intl = useIntl();

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Navigation />
      <PostWrapper>
        <Container>
          <Flex>
            <Post>
              <h1>{frontmatter.title}</h1>
              <h2>{frontmatter.date}</h2>
              <PostContent
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </Post>
          </Flex>
        </Container>
      </PostWrapper>
      <Footer />
      <CookieConsent
        location="bottom"
        onAccept={() => {
          window.trackHotjar();
          window.trackGoogleAnalytics();
        }}
        enableDeclineButton
        buttonText="Yes I accept"
        cookieName="gdpr-analytics-enabled"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {intl.formatMessage({ id: "cookie" })} <a style={{ color: "white" }} href="/privacy-policy">{intl.formatMessage({ id: "read_more" })} </a>
      </CookieConsent>
    </Layout>
  )
}

const PostContent = styled.div`
  h1 {
    margin-top: 8px;
    margin-bottom: 2px;
    color: ${props => props.theme.color.primary};
    font: 700 28px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }
`

const Post = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 120px;    
    @media (max-width: ${props => props.theme.screen.md}) {
    }

    h1 {
      margin-top: 8px;
      margin-bottom: 2px;
      color: ${props => props.theme.color.primary};
      font: 700 28px ${props => props.theme.font.primary}, sans-serif;
    }

    h2 {
      margin-bottom: 24px;
      color: ${props => props.theme.color.accent};
      font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
    }
`

const PostWrapper = styled.header`
    background-color: ${props => props.theme.color.background.light};
    position: relative;
    padding: 60px 20px 40px 20px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
    @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

export const pageQuery = graphql`
query($slug: String!, $language: String!) {
  markdownRemark(frontmatter: { slug: { eq: $slug }, lang: { eq: $language } }) {
    html
    frontmatter {
      date
      slug
      title
      lang
    }
  }
}
`
